/* eslint-disable no-prototype-builtins */
/* eslint-disable no-console */
import {
  BI_ENDPOINT as BI_VIEWER_ENDPOIINT_PREVIEW,
  BI_VIEWER_ENDPOINT as BI_VIEWER_ENDPOINT_LIVE,
  BI_ERROR_ENDPOINT,
} from '@wix/wix-data-client-common/dist/esm/bi/constants'
import {
  SentryProjects,
  configureForViewerWorker,
} from '@wix/wix-data-client-common'
import { APP_NAME } from '../helpers/constants'
import {
  Bi,
  Trace,
  Breadcrumb,
  DataBindingError,
  UserError,
  VerboseMessage,
  UnhandledPromiseRejection,
  ConsoleEvent,
} from '../logger'

const { USER_SCOPE, APPLICATION_SCOPE, SERVER_SCOPE } = DataBindingError.scopes

const {
  DBSMViewer: { dsn: appDsn },
  UserErrors: { dsn: userDsn },
  WixData: { dsn: serverDsn },
} = SentryProjects

const scopeToDsn = {
  [APPLICATION_SCOPE]: appDsn,
  [USER_SCOPE]: userDsn,
  [SERVER_SCOPE]: serverDsn,
}

// unfortunatly js decorators spec isn't stabilazed yet.
// so welcome debatable workaround
let loggingOnProd = function (Type, log) {
  return event => {
    if (this.mode.dev) {
      // TODO: add cli param for verbose logging i.e. if (verbose) console.log(event)
      if (Type === Error) {
        this.console.error(event)
        if (event.cause) {
          this.console.error('Caused by:', event.cause)
        }
      }

      return event.run?.()
    } else {
      return log(event)
    }
  }
}

export default class Logger {
  constructor({ fedops, bi, monitor, verbose, console, global, settings }) {
    const consoleLogger = console.factory()

    loggingOnProd = loggingOnProd.bind({
      mode: settings.mode,
      console: consoleLogger,
    })

    this.#eventToHandler = this._createEventToHandler()
    this.#fedOpsLogger = this._createFedopsLogger(fedops)
    this.#bi = this._createBiLoggers({ bi, settings })
    this.#monitor = this._setupMonitor({ monitor, global, settings })
    this.#verboseLogger = verbose.factory()
    this.#console = consoleLogger
  }

  log(...events) {
    if (events.length > 1) {
      return events.map(event => this.log(event))
    }

    const [event] = events
    const [, handle] =
      Array.from(this.#eventToHandler).find(([Type]) =>
        Type.prototype.isPrototypeOf(event),
      ) || []

    return handle
      ? handle(event)
      : this.#console.error(
          `Oj-vej! This event is not supported by logger`,
          event,
        )
  }

  #eventToHandler
  #fedOpsLogger
  #bi
  #monitor
  #verboseLogger
  #console

  _createEventToHandler() {
    return new Map([
      [
        Error,
        loggingOnProd(Error, error => {
          if (error instanceof UnhandledPromiseRejection) {
            this.#console.warn(
              'You have unhandled error in async operation. Consider catching it and handling accordingly.\n',
              error.cause,
            )
          } else {
            const {
              scope,
              cause,
              options: { extra, ...restOptions } = {},
            } = error

            if (error instanceof UserError) {
              this.#console.error(error)
              if (cause) {
                this.#console.error('Caused by:', cause)
              }
            }

            // now under the hood of our monitor Raven with older version of sentry is used
            // new Sentry version should support "cause" from the box, so no need to add it to extras.
            this.#monitor[scope].captureException(error, {
              zone: scope,
              extra: { cause, ...extra },
              ...restOptions,
            })
          }
        }),
      ],
      [
        Bi,
        loggingOnProd(Bi, ({ event, type }) =>
          type === Bi.types.ERROR
            ? this.#bi.errorLogger.log(event)
            : this.#bi.logger.log(event),
        ),
      ],
      [
        Trace,
        loggingOnProd(Trace, ({ name, params, run, onStart, onEnd }) => {
          onStart(() => this.#fedOpsLogger.interactionStarted(name, params))
          onEnd(() => this.#fedOpsLogger.interactionEnded(name, params))

          return run()
        }),
      ],
      [
        Breadcrumb,
        loggingOnProd(Breadcrumb, ({ event }) =>
          this.#monitor[APPLICATION_SCOPE].captureBreadcrumb(event),
        ),
      ],
      [VerboseMessage, ({ messages }) => this.#verboseLogger.log(...messages)],
      [ConsoleEvent, ({ message, level }) => this.#console[level](message)],
    ])
  }

  _createFedopsLogger({ factory, hooks: { start, end } }) {
    return factory.getLoggerForWidget({
      appId: 'databinding',
      appName: 'databinding',
      startHook: start,
      endHook: end,
      // widgetId: 'dataset',
    })
  }

  _createBiLoggers({ bi: { factory }, settings: { env } }) {
    return {
      logger: factory().logger({
        endpoint: env.editor
          ? BI_VIEWER_ENDPOIINT_PREVIEW
          : BI_VIEWER_ENDPOINT_LIVE,
      }),
      errorLogger: factory().logger({
        endpoint: BI_ERROR_ENDPOINT,
      }),
    }
  }

  _setupMonitor({
    monitor: { factory },
    global,
    settings: { metaSiteId, userId },
  }) {
    return Object.entries(scopeToDsn).reduce((acc, [scope, dsn]) => {
      const monitor = factory(dsn)

      acc[scope] = monitor
      configureForViewerWorker({
        dsn,
        Raven: monitor,
        globalScope: global,
        appName: APP_NAME,
        user: { id: userId },
        params: { tags: { msid: metaSiteId } },
      })

      return acc
    }, {})
  }
}
